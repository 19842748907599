.statusBar {
  background-color: $black;
  padding: 8px 24px;
  color: $gray-text;
  display: flex;
  justify-content: space-between;
  user-select: none;
  gap: 8px;
  position: relative;
  width: 100%;
  &.full-width {
    padding: 8px 40px;
  }
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: $gray-text;
    }
  }
  .patients-bx {
    display: flex;
    align-self: center;
    width: max-content;
    white-space: nowrap;
    font-size: 0.9rem;
  }
  .title {
    display: flex;
    align-items: center;
    h3 {
      margin: auto;
      white-space: nowrap;
      font-size: 18px;
      font-weight: 600;
      font-family: "Montserrat";
    }
  }
  .content {
    .global-error-bx {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    align-items: center;
    align-content: center;
    gap: 8px;
    height: 32px;
    p {
      font-family: "Inter";
    }
    .take-a-break-bx {
      width: fit-content;
      justify-self: right;
    }
  }
  .user-info {
    z-index: 9999;
    width: fit-content;
    white-space: nowrap;
    display: grid;
    align-items: center;
    grid-template-columns: 16px auto auto;
    gap: 4px;
    margin-left: 10px;
    p {
      font-family: "Inter";
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .floating-form {
    padding: 1.5%;
    background-color: rgba(24, 36, 48, 0.8);
    position: absolute;
    right: 20px;
    top: 50px;
    z-index: 999;
    color: $gray-eight;
    .title {
      font-weight: 600;
      font-family: "Montserrat";
      font-size: 1.3rem;
      text-align: left;
    }
  }
  .switch-user-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
}
