@function size($n) {
  @return #{$n * $unit};
}

@for $i from 0 to $limit {
  .m-#{$i} {
    margin: #{$i * $unit};
  }
}

@for $i from 0 to $limit {
  .p-#{$i} {
    margin: #{$i * $unit};
  }
}

@for $i from 0 to $limit {
  .py-#{$i} {
    padding: #{$i * $unit} 0;
  }
}

@for $i from 0 to $limit {
  .my-#{$i} {
    margin: #{$i * $unit} 0;
  }
}

@for $i from 0 to $limit {
  .mt-#{$i} {
    margin-top: #{$i * $unit};
  }
}

@for $i from 0 to $limit {
  .cam-pos-#{$i} {
    margin-top: #{$i * $unit};
  }
}

.App {
  background-color: $gray-six;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: all 0.2ms ease;
  .start {
    justify-items: start;
  }
  .end {
    justify-items: end;
  }
  &.center {
    justify-content: center;
  }
  .between {
    justify-content: space-between;
  }
  .align-center {
    align-items: center;
  }
  .align-bottom {
    align-items: flex-end;
  }
  .align-baseline {
    align-items: baseline;
  }
  .reverse-order {
    flex-direction: row-reverse;
  }
  .wrap {
    flex-wrap: wrap;
  }
  &.column {
    flex-direction: column;
  }
  &:has(.active-sidebar) .sidebar-col {
    left: 0;
  }
}
.col-100 {
  width: 100%;
}
.col-80 {
  width: 80%;
}
.col-25 {
  width: 25%;
}
.col-20 {
  width: 20%;
}
.col-75 {
  width: 75%;
}
$sidebar-percentage: 19%;
.sidebar-col {
  width: $sidebar-percentage;
  transition: all 0.2ms ease;
  border-right: 4px solid $gray-six;
}

.cams-container-col:not(:has(.cams-container-void)) {
  width: calc(100% - $sidebar-percentage);
}
.menuIconBx {
  display: none;
  align-items: center;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: rgb(24, 36, 47, 0.97);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  &.dark {
    background-color: $black;
  }
  &.dark-transparent {
    background: rgba(24, 36, 48, 0.85);
  }
  .title-bx {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    color: $gray-six;
  }
  .title {
    font-weight: 600;
    color: $gray-six;
    font-size: 1.5rem;
  }
  .normal-text {
    font-size: 1.2rem;
  }
  .dialog {
    background-color: $black;
    padding: 32px;
    width: 550px;
    color: $gray-text;
    font-family: "Montserrat";
    position: relative;
    .text-big {
      font-size: 1.3rem;
      span {
        font-weight: 600;
        text-transform: capitalize;
      }
    }
    .title-2 {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .subtitle {
      font-size: 1.2rem;
      font-weight: 400;
    }
    .arrow {
      margin: 0;
    }
    .close {
      position: absolute;
      top: 35px;
      right: 30px;
    }
    .label {
      font-size: 0.8rem;
      margin-bottom: 4px;
    }
    span {
      &.green {
        color: $mint;
      }
      &.yellow {
        color: $yellow;
      }
      &.red {
        color: $red;
      }
    }
  }
}

.modals-stack {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 15px;
  bottom: 58px;
  max-height: 75%;
  overflow-y: auto;
}

.modal {
  p {
    user-select: none;
  }
  padding: 10px 20px;
  background-color: $darker-blue;
  color: $gray-five;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: "Inter";
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &.short {
    width: 200px;
  }
  &.black {
    background-color: $black;
  }
  .undo-btn {
    color: $green;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
}

.floating-modal {
  display: flex;
  padding: 2px 15px;
  flex-direction: column;
  position: fixed;
  border-radius: 5px;
  background-color: rgba(27, 62, 111, 0.6);
  box-shadow: 2px 2px 2px rgba(124, 123, 123, 0.5);
  transition: all 0.5s ease;
  bottom: -150px;
  &.left {
    left: 30px;
  }
  &.right {
    right: 30px;
  }
  &:hover {
    bottom: 10px;
    background-color: rgba(27, 62, 111, 1);
  }
}

@media screen and (min-width: 1920px) {
  $sidebar-per-big: $sidebar-percentage - 1%;
  .sidebar-col {
    width: $sidebar-per-big;
  }
  .cams-container-col:not(:has(.cams-container-void)) {
    width: calc(100% - $sidebar-per-big);
  }
}

@media screen and (max-width: 720px) {
  .sidebar-col {
    position: absolute;
    width: 100%;
    left: -100%;
    top: 55px;
  }
  .cams-container-col:not(:has(.cams-container-void)) {
    width: 100%;
  }
  .statusBar {
    .menuIconBx {
      display: grid;
    }
    &.active-sidebar {
      svg {
        fill: $mint;
      }
    }
  }
}

.expansion-arrow {
  cursor: pointer;
  transition: all 0.2s ease;
}

.arrow {
  &.left {
    transform: rotate(90deg) !important;
  }
  &.up {
    transform: rotate(180deg) !important;
  }
  &.right {
    transform: rotate(-90deg) !important;
  }
  &.down {
    transform: rotate(0) !important;
  }
}

.loaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.collapsed {
  .expansible-menu {
    display: none;
  }
  .expansion-arrow {
    transform: rotate(0);
  }
  .dropdown-overlay {
    display: none;
  }
}
.expanded {
  .expansible-menu {
    display: block;
    z-index: 999;
  }
  .expansion-arrow {
    transform: rotate(180deg);
  }
  .dropdown-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 100;
  }
}

.p-alert {
  cursor: pointer;
  p {
    width: fit-content;
    font-weight: 600 !important;
    color: $medium-badge;
  }
  &:hover p {
    border-bottom: 1px solid $medium-badge;
  }
  &.low-priority {
    p {
      width: fit-content;
      color: $mint;
    }
    &:hover p {
      border-bottom: 1px solid $mint;
    }
  }
}

.dropdown-menu {
  position: absolute;
  right: 35px;
  top: 40px;
  background-color: $darker-blue;
  padding: 6px 16px;
  color: $gray-text;
  font-family: "Inter";
  z-index: 998;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 6px 2px rgba(0, 0, 0, 0.2);
  p {
    width: fit-content;
    text-align: left;
    font-size: 0.8rem !important;
    cursor: pointer;
    padding: 5px 0;
    &:hover {
      font-weight: 600 !important;
      color: $mint;
    }
  }
  span {
    font-family: "Inter";
    font-weight: 400;
    color: $gray-five;
    font-size: 0.8rem !important;
    &:hover {
      color: $mint;
      font-weight: 500;
    }
  }
  &.loading {
    p {
      color: $gray-seven;
      cursor: progress;
      &:hover {
        font-weight: 400 !important;
      }
    }
  }
  &.wide {
    min-width: 150px;
  }
  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.loader {
  border: 8px solid $ochsner-blue;
  border-top: 8px solid $gray-six;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 5px;
  animation: spin 1s linear infinite;
  position: relative;
  margin: auto;
  top: calc(50% / 2);
  box-shadow: $box-shadow-soft;
  &.small {
    border-width: 4px;
    width: 40px;
    height: 40px;
  }
  &.xsmall {
    border-width: 4px;
    width: 25px;
    height: 25px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sidebar,
.cams-container-bx {
  svg {
    margin-right: 10px;
  }
}

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  &:has(.tooltiptext) {
    cursor: pointer;
  }
  .tooltiptext {
    visibility: hidden;
    padding: 2px 8px;
    background-color: $tooltip-bg;
    color: $white-text;
    text-align: center;
    position: absolute;
    z-index: 9999;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 24px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    transform: translateX(-30%);
    white-space: nowrap;
    cursor: default;
    &.top {
      transform: translate(30%, -30%);
    }
    &.right {
      transform: translate(23%, 90%);
    }
    &.down {
      transform: translate(0%, 210%);
    }
    &.left {
      transform: translate(-103%, 93%);
    }
    &:hover {
      visibility: hidden;
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    bottom: 90%;
  }
}

.big-btn {
  padding: 8px 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

.big-textarea {
  padding: 10px 10px 7px;
  font-size: 0.8rem;
  width: 100%;
  margin-bottom: 10px;
  resize: none;
}

.form-box {
  padding: 30px 26px 27px;
  margin: 5px 0 0 0;
  &.filled {
    background-color: $ochsner-blue;
  }
  &.outlined {
    border: 1px solid $textarea-plh;
  }
  .form-options {
    margin: 20px 0;
    width: 100%;
    .form-control {
      margin: 12px 0;
    }
    .check-input {
      color: $gray-text;
      display: grid;
      grid-template-columns: 1em auto;
      gap: 0.5em;
      input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;

        border: 1px solid $gray-text;
        border-radius: 1px;
        background-color: transparent;
        width: 12px;
        height: 12px;
        cursor: pointer;

        transform: translateY(-0.075em);

        display: grid;
        place-self: center;

        &::before {
          content: "";
          clip-path: polygon(
            17% 49%,
            11% 56%,
            40% 78%,
            93% 20%,
            86% 13%,
            38% 65%
          );
          width: 10px;
          height: 10px;
          background-color: rgba(0, 0, 0, 0.87);
          transform: scale(0);
          transition: 120ms transform ease-in-out;
        }
        &:checked {
          background: $mint;
          &::before {
            transform: scale(1);
          }
        }
      }
    }
  }
}

.form-100 {
  width: 100%;
}

.black-input {
  background-color: $black;
  border: 1px solid #7c7b7b;
  color: $gray-six;
  &.disabled {
    color: #7c7b7b;
  }
  &::placeholder {
    color: $textarea-plh;
  }
  &:hover:not(.big-textarea) {
    background-color: $darker-blue;
  }
}

.opaque-input {
  border: 1px solid #7c7b7b;
  background-color: rgba(0, 0, 0, 0.2);
  color: $gray-five;
  svg {
    path {
      fill: $gray-five;
    }
  }
  &.disabled {
    color: $disable-btn;
    border-color: $disable-btn;
    svg {
      path {
        fill: $disable-btn;
      }
    }
  }
  &:hover:not(.disabled) {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.fixed-textarea {
  resize: none;
}

.medium-input {
  padding: 5px;
  margin: 5px 0;
  width: 250px;
  font-family: "Inter";
  font-size: 0.9rem;
}
.blue-input {
  &.transparent {
    background-color: transparent;
    color: #fff;
    border: solid 2px $gray-six;
    outline: none;
  }
  &.thin {
    border: solid 1px $gray-seven;
    padding: 3px 8px;
    width: 100%;
  }
}

.btn-group {
  align-items: center;
  gap: 8px;
  &.right {
    justify-content: end;
  }
  &.left {
    justify-content: start;
  }
  &.center {
    justify-content: center;
  }
  &.same-width {
    .btn {
      width: 110px;
    }
  }
}

.btn-small {
  padding: 8px 12px;
  font-size: 0.8rem;
  line-height: 17px;
  font-weight: 500;
  min-width: 93px;
}

.btn-medium {
  padding: 8px 12px 8px 10px;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid $border-btn;
  margin: 8px 0;
  align-items: center;
  font-weight: 600;
  svg {
    margin-right: 4px;
    margin-top: 3px;
  }
}

.btn-xsmall {
  padding: 4px 8px;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid $border-btn;
  margin: 8px 0;
  align-items: center;
  svg {
    margin-right: 4px;
    margin-top: 3px;
  }
}

.btn-mini {
  padding: 4px 12px;
  font-size: 0.9rem;
  font-weight: 600 !important;
  text-align: center;
}

button,
.btn {
  &:not(.disabled) {
    transition: all 0.2s ease-in-out;
  }
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: none;
  font-weight: 600;

  svg {
    margin: 0 !important;
    width: 16px !important;
    height: 16px !important;
  }
  &.icon {
    display: grid;
    grid-template-columns: 16px auto;
    gap: 4px;
    align-items: center;
  }
  &.outlined {
    border: 1px $gray-six solid;
  }
  &.transparent {
    background-color: transparent;
    color: $gray-six;
    &:hover {
      background-color: $black;
    }
  }
  &.light {
    background-color: $gray-six;
    color: $dark-text;
    border: $gray-six solid 1px;
    &:hover {
      background-color: $light-gray-bg;
    }
  }
  &.red {
    color: #fff;
    background-color: $red;
    path {
      fill: #fff;
    }
  }
  &.yellow {
    color: #000;
    background-color: $medium-badge;
    path {
      fill: #000;
    }
  }

  &:disabled,
  &.disabled {
    transition: none 0s;
    color: #524f4f;
    svg {
      path {
        fill: $disable-btn;
      }
    }
  }
  &.green {
    background-color: $green;
    color: $black;
    &:hover {
      background-color: $mint-light;
    }
    &:active {
      transform: scale(1.05);
    }
  }
  &.dark-blue {
    background-color: $darker-blue;
    &:hover {
      background-color: $ochsner-blue;
    }
    &:active {
      transform: scale(1.05);
    }
  }
  &.btn-huge {
    padding: 12px 24px;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.disabled {
  cursor: not-allowed;
}

.large-btn {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 0.9rem;
  line-height: 24px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.alert {
  padding: 16px;
  color: #000;
  width: 100%;
  display: flex;
  line-height: 19px;
  justify-content: space-between;
  margin: 20px 0;
  font-family: "Inter";
  border-radius: 4px;
  &.alert-success {
    background-color: $mint;
  }
  &.alert-info {
    background-color: $ochsner-blue;
    color: $gray-six;
  }
}

.invisible {
  display: none !important;
}

.edit-icon {
  transition: all ease 0.25s;
}

.pagination {
  display: flex;
  flex-wrap: nowrap;
  gap: 9px;
}
.error-icon {
  cursor: pointer;
  path {
    fill: $error-bg !important;
  }
}

.global-errors {
  padding: 6px 16px;
  display: flex !important;
  flex-wrap: nowrap;
  gap: 12px;
  border: 0 !important;
  .close-icon {
    align-self: center !important;
  }
}

.error-msg {
  color: $error-bg;
  svg {
    path {
      fill: $error-bg !important;
    }
  }
  &.inline {
    svg {
      width: 12px !important;
      height: 12px !important;
    }
    font-size: 0.8rem;
  }
  &.top {
    align-self: start;
    svg {
      width: 15px;
      height: 15px;
    }
  }
  &.bottom {
    align-self: end;
  }
  &.center {
    align-self: center;
  }
  &.absolute {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    &.bottom {
      bottom: 40px;
    }
    &.top {
      top: 80px;
    }
  }
  &.w-100 {
    width: 100%;
    max-width: 100%;
  }
  &.w-auto {
    width: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &.filled {
    color: #000 !important;
    background-color: $error-bg !important;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    svg {
      path {
        fill: #000 !important;
      }
    }
  }
  &.p-big {
    padding: 20.5px 23.5px 20.5px 33.5px;
    grid-template-columns: 15px auto 15px;
    column-gap: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &.p-small {
    padding: 8px 16px;
    grid-template-columns: 280px 12px;
    font-size: 0.8rem;
  }
  &.p-none {
    padding: 0;
    grid-template-columns: 12px auto;
    column-gap: 7px;
  }
  display: grid;
  align-items: center !important;
  text-align: left;
  gap: 8px;
  svg {
    &.disabled {
      path {
        fill: $gray-text !important;
      }
      cursor: default;
    }
  }
  .info-icon {
    align-self: flex-start;
    margin-top: 2px;
  }
  .close-icon {
    align-self: flex-start;
    height: 16px;
    width: 16px;
  }
  .message {
    margin: 0;
    line-height: normal !important;
  }
}

.loading {
  cursor: progress !important;
}

.circle {
  border-radius: 50%;
  &.small {
    height: 10px;
    width: 10px;
  }
  &.green {
    background-color: $mint;
  }
}

.circle {
  border-radius: 50%;
  &.small {
    height: 10px;
    width: 10px;
  }
  &.green {
    background-color: $mint;
  }
}

.circle-icon {
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s ease;
  svg {
    width: 18px !important;
    height: 18px !important;
    margin: 0 !important;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.pill-alert {
  padding: 8px;
  border-radius: 16px;
  font-weight: 700 !important;
  font-family: "Inter";
  font-size: 0.75rem;
  transition: all 0.2s ease-out;
  &.error {
    background-color: $error-bg;
    color: $error-two;
  }
  &.info {
    background-color: $blue-light;
    color: $ochsner-blue;
  }
  &.floating {
    position: absolute;
    box-shadow: 0px 2px 2px rgba(24, 36, 48, 0.4);
    &.top {
      top: 12px;
    }
    &.centered {
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.large {
    padding: 5px 16px;
    margin-left: 4px;
    margin-right: 8px;
    white-space: nowrap;
    display: block;
  }
}

.form-control {
  width: 100%;
  max-width: 258px;
  margin: 2px 0 10px;
  white-space: pre-wrap;
  &.halfWidth {
    width: 30%;
    margin: 0px 0px 0px 12px;
  }
  &.small {
    width: fit-content;
    margin: 0px 15px 0px 0px;
  }
  &.radio-option {
    display: grid;
    align-items: center;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    input[type="radio"] {
      align-self: center;
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      color: $green;
      border: 1px solid $gray-six;
      border-radius: 50%;
      cursor: pointer;
      display: grid;
      place-content: center;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transition: 120ms transform ease-in-out;
        background-color: $green;
        transform: scale(0);
        opacity: 0;
      }
      &:checked {
        border: 2px solid $green;
      }
      &:checked::before,
      &:focus {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  label {
    font-size: 0.8rem;
    span {
      font-weight: 700;
    }
  }
  .error-label {
    display: grid;
    grid-template-columns: 10px auto;
    align-items: baseline;
    gap: 4px;
    font-size: 0.75rem;
    color: $error-bg;
    svg {
      width: 10px;
      height: 10px;
      path {
        fill: $error-bg;
      }
    }
  }

  .helper-text {
    font-size: 0.7rem;
    font-weight: 400;
    color: $gray-text;
    margin-left: 8px;
  }

  .react-time-picker__inputGroup {
    min-width: 40px;
    height: 20px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    .react-time-picker__inputGroup__input,
    span {
      margin: 0;
      padding: 1px;
      color: $gray-six;
      border: none !important;
      outline: none;
      font-size: 0.9rem;
      font-family: "Montserrat";
    }
  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    filter: invert(0.5) saturate(5) hue-rotate(175deg);
  }
}

.mini-form {
  .image-option-bx {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px 12px;
    &:hover {
      background-color: rgb(16, 24, 32, 0.25);
    }
    &.active {
      background-color: rgb(16, 24, 32, 0.5);
      border: 1px solid $gray-text;
    }
    p {
      margin-top: 4px;
      font-size: 0.75rem;
      color: $gray-six;
    }
    svg {
      margin: 0;
      height: 32px;
      width: 32px;
      &.normal {
        path {
          fill: $mint;
        }
      }
      &.warning {
        path {
          fill: #ffb800;
        }
      }
      &.danger {
        path {
          fill: #ff0000;
        }
      }
    }
  }
}

input[type="text"],
input[type="number"],
input[type="time"],
input[type="password"],
select,
.react-time-picker__wrapper,
input[type="datetime-local"],
#phoneNumber {
  font-size: 0.9rem;
  font-family: "Montserrat";
  margin-top: 4px;
  width: 100%;
  max-width: 500px;
  padding: 8px 12px;
  border: 1px solid $gray-six !important;
  border-radius: 4px;
  background-color: transparent;
  color: $gray-six;
  &.error {
    border: 2px solid $error-bg;
  }
  &:focus {
    border-color: white;
    box-shadow: 0px 3px 5px 3px $black;
    outline: 2px;
  }
}
select {
  position: relative;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, $gray-six 50%),
    linear-gradient(135deg, $gray-six 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  option {
    background-color: $dark-blue;
  }
  &::-ms-expand {
    display: none;
  }
  &:focus {
    background-image: linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }
}
::-ms-reveal {
  filter: invert(100%);
}

.simple-table {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid $gray-six;
  }

  tbody tr:nth-child(even) {
    background-color: $ochsner-blue;
  }
  tbody tr:nth-child(odd) {
    background-color: $blue-hover;
  }
}

.breadcrumb {
  background-color: $darker-blue;
  padding: 25px 30px 10px;
  a {
    text-decoration: none !important;
  }
  .breadcrumb-title {
    font-size: 1rem;
    text-decoration: none;
    margin: 0 10px;
    color: $gray-text;
    text-transform: capitalize;
    &.active {
      font-weight: 600;
    }
  }
  svg {
    height: 16px !important;
    width: 16px !important;
    path {
      fill: $gray-text;
    }
  }
}

.card {
  max-width: 300px;
  height: auto;
  p {
    &.bold {
      font-weight: 600;
    }
  }
}

.alert-text {
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Inter";
  color: $error-bg;
  &:hover {
    border-bottom: 1px solid $error-bg;
  }
}

.icons-row {
  display: flex;
  align-items: baseline;
  justify-items: center;
  gap: 8px;
  width: min-content;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat";

  svg {
    height: 16px;
    width: 16px;
  }
}

.icon-yellow {
  svg {
    path {
      fill: $yellow !important;
    }
  }
}

.icon-red {
  svg {
    path {
      fill: $error-bg !important;
    }
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &.one {
    -webkit-line-clamp: 1;
  }
}

.notification {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 6px 16px;
  align-items: center;
  background-color: $mint;
  color: #000;
  width: fit-content;
  svg {
    path {
      fill: #000 !important;
    }
  }
}
