$white-bg: #fff;
$light-gray-bg: #d9d8d6;
$dark-blue: #002d5d;
$darker-blue: #182430;
$ochsner-blue: #1b3e6f;
$black: #101820;
$dark-gray: #595959;
$light-gray: #a4dbc0;
$medium-badge: #ffb800;
$high-badge: #dc3333;
$gray-six: #e2dfdf;
$gray-five: #e0e0e0;
$tooltip-bg: #283849;
$border-btn: #e5e5e5;
$red: #dc3333;
$error-bg: #cf6679;
$blue-hover: #202f3e;
$blue-light: #37b3e8;
$status-bar-height: 64px;

//text
$white-text: #fff;
$emerald: #006646;
$mint: #66c295;
$light-blue: #0096d6;
$caution: #ebab21;
$success: #6cb33e;
$error: #aa182c;
$error-two: #970822;
$gray-text: #bdbdbd;
$dark-title: #333;
$textarea-plh: #8f8f8f;
$dark-text: #00244a;
$gray-six2: #f2f2f2;
$gray-seven: #828282;
$gray-eight: #e0e0e0;
$disable-btn: #7c7b7b;
$green: #4bc17e;
$mint-light: #66c295;
$yellow: #ffb800;

$box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.6);
$box-shadow-soft: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;1,500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gaegu:wght@300;400;700&family=Inter:wght@100;300;400;500;600;700;900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap");
$limit: 10;
$unit: 8px;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", "Inter", sans-serif;
}

body {
  height: 100vh;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  cursor: auto;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(164, 219, 192, 0.305);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $ochsner-blue;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-blue;
}
