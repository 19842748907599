$breadcrumb-height: 54px;
$logout-height: 172px;
.container {
  background-color: $darker-blue;
  width: 100%;
  height: calc(100vh - $status-bar-height - $logout-height);
  padding: 20px 40px;
  color: $gray-text;
  font-family: "Montserrat";
  overflow-y: auto;
  position: relative;
  // overflow: auto;
  &.compressed {
    height: calc(100vh - $status-bar-height - $breadcrumb-height);
  }
  svg {
    path {
      fill: $gray-text;
    }
  }
  .arrow {
    margin-right: 20px;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
  }
  h4 {
    font-size: 20px;
    font-weight: 400;
    color: $gray-text;
    margin-left: 20px;
  }
  .info {
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    color: $gray-six2;
  }
  .table-container {
    display: flex;
  }
  .table {
    font-size: 16px;
    border-collapse: collapse;
    width: 100%;
    height: min-content;
    max-width: 1552px;
    transition: all 0.2s ease;
    color: #fff;
    user-select: none;
    overflow-x: auto;
    thead {
      th {
        font-weight: 400;
        &:last-child {
          max-width: 240px;
        }
      }
    }

    th,
    td {
      text-align: left;
      padding: 18px 16px;
      &.text-left {
        text-align: left;
      }
      &.text-center {
        text-align: center;
      }
    }
    tbody {
      &.interactive {
        tr {
          &:hover {
            background-color: rgba(102, 99, 99, 0.5);
            cursor: pointer;
          }
          &:active .main-td {
            text-decoration: underline;
          }
        }
      }
      &.hover {
        tr:hover {
          background-color: rgba(102, 99, 99, 0.5);
        }
      }
      tr {
        .options {
          /*  display: none; */
          display: flex;
          justify-content: center;
          position: relative;
          .outer-circle {
            content: "";
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2px;
            flex-direction: column;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            cursor: pointer;
            &:hover,
            &.active {
              background-color: $black;
            }
          }
          .circle {
            position: relative;
            width: 3px;
            height: 3px;
            background-color: $gray-text;
            border-radius: 50%;
          }
        }
      }
      td {
        border-bottom: 1px solid rgba(102, 99, 99, 0.5);
        &.pill {
          p {
            white-space: nowrap;
            color: $black;
            border-radius: 20px;
            width: min-content;
            padding: 4px 16px;
            background-color: #37b3e8;
          }
          &.active {
            p {
              background-color: $mint;
            }
          }
          &.on.break {
            p {
              background-color: $yellow;
            }
          }
          &.in.room,
          .in.the.room {
            p {
              background-color: $mint;
            }
          }
        }
      }
    }
  }
  .fixed-button {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 16px 33px;
    background-color: $black;
    display: flex;
    align-items: center;
    max-height: 56px;
    align-self: flex-end;
  }
}

.analytics-row {
  display: grid;
  grid-template: 300px/ 320px 680px 520px;
  gap: 16px;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.card-black {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 208px;
  background-color: $black;
  padding: 16px 32px;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Inter";

  p.bold {
    font-weight: 600;
  }

  p.title {
    font-weight: 700;
    font-size: 24px;
    font-family: "Montserrat";
  }
  p.highlight {
    font-family: "Inter";
    font-size: 1.5rem;
    font-weight: 600;
    color: $mint;
  }

  .issues-list {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 200px;
    overflow-y: auto;
    justify-content: left;

    .issue-row {
      display: flex;
      flex-direction: row;
      gap: 8px;
      font-weight: 500;
      font-size: 0.8rem;
      align-items: center;

      p.yellow {
        color: $yellow;
      }

      p.red {
        color: $error-bg;
      }
    }
  }

  .tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    margin-left: 24px;
    .tab {
      cursor: pointer;
      font-size: 0.9rem;
      color: $gray-five;
      &.isActive {
        font-weight: 700;
        color: $mint;
      }
      &:hover {
        color: $mint;
      }
    }
  }
}

.progress_container {
  width: 85%;
  height: 2rem;
  position: relative;
  transition: all 0.5s;
  will-change: transform;
  display: flex;
  align-items: center;

  .progress {
    height: 100%;
    content: "";
    background-color: $blue-light;
    top: 0;
    left: 0;
    border-radius: inherit;
    overflow-x: visible;
    &.green {
      background-color: $mint;
    }
    &.yellow {
      background-color: $yellow;
    }
    &.red {
      background-color: $error-bg;
    }
  }
  span {
    font-family: sans-serif;
    color: white;
    font-size: 0.8rem;
    margin-left: 10px;
    width: 100%;
  }
}
select {
  position: relative;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, $gray-six 50%),
    linear-gradient(135deg, $gray-six 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  background-color: $dark-blue;
  option {
    //background-color: blue;
  }
  &::-ms-expand {
    display: none;
  }
  &:focus {
    background-image: linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }
}

.containerLogout {
  background-color: $darker-blue;
  color: white;

  form {
    color: $gray-text;
    font-family: "Montserrat";
    background-color: $darker-blue;
    margin: 0;
    height: 200px;
  }
}


.btn-xsmall {
  padding: 4px 8px;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid $border-btn;
  margin: 8px 0;
  align-items: center;
  svg {
    margin-right: 4px;
    margin-top: 3px;
  }
}
