.cams-container-col {
  height: 100vh;
  .large-errors {
    width: 100%;
    max-height: 61px;
    overflow: hidden;
  }
}
$status-bar-height: 48px;
.cams-container-bx {
  overflow-y: auto;
  height: calc(100vh - $status-bar-height);
  position: relative;
}

.cams-container {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr;
  &:has(.main-grid) {
    &:has(.grid-0) {
      grid-template-rows: 0fr 12fr;
    }
    &:has(.grid-2) {
      grid-template-rows: 4fr 8fr;
    }
    &:has(.grid-3) {
      grid-template-rows: 4fr 8fr;
    }
    &:has(.grid-6) {
      grid-template-rows: 8fr 4fr;
    }
    &:not(:has(.grid-0)) {
      border-top: 4px solid $gray-six;
    }
  }
  .main-grid {
    display: grid;
    gap: 4px;
    &.grid-2 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }
    &.grid-3 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }
    &.grid-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &.grid-6 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
  .secondary-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 4px;
  }
  .loaderDiv {
    margin: auto;
    transform: translateY(-56px);
  }
  .void-cam {
    height: 100%;
    width: 100%;
    background-color: $black;
  }
}

.cams-container-void {
  display: flex;
  transition: all 0.2s ease-in-out;
  align-items: center;
  text-align: center;
  height: calc(100vh - $status-bar-height);
  flex-direction: column;
  background-color: $black;
  color: $gray-six2;
  border-top: 4px solid $gray-six;
  .info {
    width: 100%;
    transform: translateY(-56px);
    margin: auto;
    h3 {
      font-size: 1.3rem;
      margin-bottom: 16px;
    }
    p {
      font-size: 1.2rem;
    }
  }
}

.cams-card .cams.loading video.player-wrapper {
  display: none;
}

.cams-card {
  cursor: pointer;
  position: relative;
  color: $gray-five;
  transition: all 0.75s ease-in-out;
  transition: border 0.2s ease-in-out;
  width: 100%;

  &.warning {
    border: 8px solid #ffb800;
  }
  &.danger {
    border: 8px solid #ff0000;
  }

  h3 {
    font-family: "Inter";
    font-weight: 700;
    font-size: 0.9rem;
    color: $gray-five;
  }

  h4 {
    font-family: "Inter";
    font-weight: 400;
    font-size: 0.9rem;
  }

  p {
    font-family: "Inter";
    font-weight: 400;
    font-size: 0.8rem;
    color: #e0e0e0;
  }

  //icons
  $icons-side: 26px;
  svg {
    height: $icons-side;
    width: $icons-side;

    path {
      fill: $gray-six;
    }
    &.selected,
    &.disabled {
      path {
        fill: #807c7c;
      }
    }
    &.active {
      path {
        fill: $mint;
      }
    }
  }

  .cams {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $darker-blue;
    img,
    video,
    canvas {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .label {
      align-self: center;
      position: absolute;
      text-align: center;
    }
    &.blur {
      filter: blur(15px);
    }
  }

  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }

  .room-number {
    display: flex;
    span {
      font-family: "Inter";
      font-weight: 400;
      font-size: 0.9rem;
    }
  }

  &:has(.patient-name.expanded) .pill.right {
    opacity: 0;
  }

  &:has(.mini-form) .pill {
    opacity: 0;
  }

  .cams-menu {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    transition: all 0.3s ease;
    box-sizing: border-box;
    cursor: default;
    z-index: 10;

    .upper-menu {
      background-color: $black;
      width: 100%;
      height: auto;
      padding: 6px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      .patient-name {
        display: flex;
        padding: 2px 4px;
        align-items: center;
        &.active {
          &:hover,
          &.expanded {
            cursor: pointer;
            background-color: $blue-hover;
          }
        }
        .circle {
          margin-left: 8px;
        }
        transition: all 0.2s ease;
      }
      .audio-icon,
      .audio-muted-icon {
        width: 20px;
        height: 20px;
      }
      svg {
        &:hover:not(.disabled):not(.noHover),
        &:focus:not(.disabled):not(.noHover),
        &:active:not(.disabled):not(.noHover) {
          path {
            fill: $mint;
          }
        }
      }
    }

    .lower-menu {
      background-color: $black;
      position: absolute;
      width: 100%;
      height: auto;
      top: 100%;
      transform: translateY(-100%);
      padding: 6px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .iconsBx {
        display: flex;
        width: 50%;
        min-width: 120px;
        max-width: 150px;
        justify-content: space-around;
      }
      svg {
        // margin-left: 10px;
        margin-right: 0;

        &:hover:not(.disabled):not(.noHover),
        &:focus:not(.disabled):not(.noHover),
        &:active:not(.disabled):not(.noHover) {
          path {
            fill: $mint;
          }
        }
      }
    }

    .mini-form {
      padding: 4% 8%;
      background: rgba(24, 36, 48, 0.8);
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translate(-50%);
      margin: auto;
      color: $gray-six;
      white-space: nowrap;
      z-index: 9999;
      overflow-y: auto;
      max-width: 90%;
      h3 {
        text-align: center;
        font-size: 0.9rem;
        font-weight: 600;
        white-space: pre-wrap;
      }
      .options-form {
        margin: 3% 0;
        width: 100%;
      }
      .close-icon {
        position: absolute;
        right: 5px;
        top: 12px;
        width: 18px;
        height: 18px;
      }
      .form-control {
        &.radio {
          margin: 5% 0;
          display: grid;
          grid-template-columns: 1em auto;
          gap: 0.5em;
        }
        &.single-input {
          max-width: 100%;
        }
        label {
          font-size: 0.8rem;
          font-weight: 400;
        }
        input[type="radio"] {
          -webkit-appearance: none;
          appearance: none;
          align-self: center;
          background-color: transparent;
          width: 16px;
          height: 16px;
          margin-right: 5px;
          color: $gray-six;
          border: 2px solid $gray-six;
          border-radius: 50%;
          cursor: pointer;
          display: grid;
          place-content: center;
          &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transition: 120ms transform ease-in-out;
            background-color: $gray-six;
            transform: scale(0);
            opacity: 0;
          }
          &:checked::before,
          &:focus {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
      .message-modal {
        p {
          margin: auto;
          white-space: normal;
          padding: 5% 0px;
          text-align: center;
          line-height: 18px;
          font-size: 0.8rem;
          font-weight: 400;
          max-width: 280px;
        }
      }
    }
  }

  &:has(.cams-menu) .audio-alert {
    top: 54px;
  }

  .audio-alert {
    transition: all 0.25s ease;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 50%;
    top: 12px;
    transform: translateX(-50%);
    text-align: center;
    border-radius: 4px;
    z-index: 999;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    &.success {
      background-color: $mint;
    }
    &.flat-borders {
      border-radius: 0;
      padding: 10px 16px;
    }
    p,
    span {
      font-family: "Inter";
      color: #000;
      font-size: 0.7rem;
      font-weight: 400;
      white-space: nowrap;
    }
    span {
      margin-left: 10px;
    }
    .btn {
      font-weight: 600;
    }
  }

  .escalation-path-name {
    position: absolute;
    top: 16px;
    left: 14px;
    transition: opacity 0.2s ease-out;
    text-shadow: 0px 2px 2px rgba(24, 36, 48, 0.4);
    display: grid;
    grid-template-columns: auto auto;
    gap: 4px;
    transition: all 0.25s ease;
  }

  &:has(.cams-menu) .pill {
    padding: 4px 8px;
    font-size: 0.9rem;
    top: 40px;

    &.green {
      background-color: #32af64;
      &:hover {
        background-color: rgb(37, 126, 73);
      }
    }
    &.large {
      grid-template-columns: 16px auto;
      padding: 4px 8px;
      h4 {
        display: none;
      }
    }
    &.centered {
      left: unset;
      transform: translateX(0);
    }
    &.right {
      right: 16px;
    }
    &.left {
      left: 16px;
    }
  }

  &:has(.cams-menu) .escalation-path-name,
  &:has(.pill.yellow) .escalation-path-name,
  &:has(.cams-menu) .pill-alert.floating {
    top: -20px;
    opacity: 0;
  }

  &:has(.pill.large.centered) .tooltip .tooltiptext.right {
    transform: translate(0%, 220%);
  }

  .pill {
    display: grid;
    place-content: center;
    grid-template-columns: 16px auto;
    gap: 2px;
    padding: 4px 6px;
    z-index: 99;
    color: #fff;
    border-radius: 16px;
    position: absolute;
    top: 12px;
    font-size: 1rem;
    font-weight: 700;
    &:active {
      transition: transform 0.1s ease;
      transform: scale(1.02);
    }
    &.right {
      right: 16px;
    }
    &.left {
      left: 16px;
    }
    $icons-side-pill: 0.9rem;
    svg {
      width: $icons-side-pill;
      height: $icons-side-pill;
      margin: 0;
      path {
        fill: #fff;
      }
    }
    &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    }
    transition: all 0.25s ease;
    //from green changes to transparent
    &.green {
      background: rgba(0, 0, 0, 0.25);
      &:hover {
        background: rgba(0, 0, 0, 0.4);
      }
    }
    &.transparent {
      background: rgba(0, 0, 0, 0.25);
      &:hover {
        background: rgba(0, 0, 0, 0.4);
      }
    }
    &.yellow {
      background-color: $medium-badge;
      svg {
        &:hover {
          path {
            fill: #000;
          }
        }
        path {
          fill: #000;
        }
      }
    }
    &.red {
      background-color: $red;
      &:hover {
        background-color: $red;
      }
    }
    &.big {
      padding: 6px 8px;
    }
    &.small {
      padding: 4px 8px;
      top: 54px;
    }
    &.large {
      padding: 5px 16px;
      grid-template-columns: 16px auto 44px;
      h4 {
        margin-left: 4px;
        margin-right: 8px;
        font-weight: 700;
        white-space: nowrap;
        display: block;
      }
    }
    &.centered {
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:has(.pill.red)::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(220, 51, 51);
    animation: pulse 0.5s ease-in-out 0s infinite alternate;
  }

  .cam-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
    user-select: none;
    gap: 6px;
    &.transparent {
      background-color: rgba(16, 24, 32, 0.75);
    }
    &.opaque {
      background-color: rgba(16, 24, 32, 0.9);
    }
    &.green {
      background-color: rgba(56, 143, 99, 0.9);
    }
    .title {
      color: #d7d3d3;
      font-size: 16px;
      font-weight: 700;
      font-family: "Montserrat";
    }
    .subtitle {
      font-size: 12px;
      font-weight: 500; /* Frame 41 */
      padding: 4px 10px;
      background: #182430;
      text-align: center;
      text-transform: uppercase;
      color: #d7d3d3;
    }
  }
}

@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@media (max-width: 1280px) {
  .cams-container {
    &.TwoXTwo {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &.ThreeXThree {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: calc(33vh - 16px);
    }
    &.FourXFour {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: calc(33vh - 16px);
    }
  }
}

@media (max-width: 720px) {
  .cams-container-col {
    padding: 0;
  }
  .cams-container {
    &.TwoXTwo {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: calc(33vh - 16px);
      grid-template-rows: none;
    }
    &.ThreeXThree {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: calc(33vh - 16px);
    }
    &.FourXFour {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: calc(33vh - 16px);
    }
  }
}
