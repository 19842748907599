.sidebar-col {
  background-color: $black;
}

.sidebar {
  background-color: $darker-blue;
  transition: all 0.2s ease-in-out;
  height: 100vh;
  line-height: 24px;
  font-size: 0.9rem;
  color: $gray-text;
  display: grid;
  position: relative;
  grid-template-rows: 87px auto auto;
  &.void {
    border: 1px solid $darker-blue;
    .loaderDiv {
      margin-top: 400px;
    }
  }

  &:has(.nurse-call .form-box.filled) {
    .sticky-notes {
      color: $disable-btn;
      h3 {
        color: $disable-btn;
      }
    }

    .patient-history {
      color: $disable-btn;
      h3 {
        color: $disable-btn;
      }
      svg {
        path {
          fill: $disable-btn;
        }
      }
    }
  }

  .sidebar-title-bx {
    padding: 36px 31px 27px;
    background-color: $black;
    display: flex;
    align-items: center;
    max-height: 87px;

    .arrow {
      height: 32px !important;
      width: 32px !important;
    }

    &:has(.title-grid) {
      padding: 21px 29px 14px 33px;
      flex-direction: column;
      align-items: flex-start;
    }

    .title-grid {
      display: grid;
      margin-top: 6px;
      grid-template-columns: auto auto;
      gap: 10px;
    }
    .sidebar-title {
      width: 100%;
      display: flex;
      align-items: center;
      p {
        color: $gray-six;
        font-size: 1.2rem;
        font-weight: 700;
      }
      svg {
        justify-self: flex-end;
        margin: 0;
      }
    }
    .sidebar-subtitle {
      font-size: 0.9rem;
      color: $gray-five;
      font-weight: 500;
    }
  }

  p {
    margin-right: 10px;
    font-weight: 400;
  }

  h3 {
    font-size: 0.9rem;
    font-weight: 600;
    color: $gray-five;
    width: 100%;
  }

  h2 {
    font-size: 0.9rem;
    font-weight: 700;
    color: $gray-five;
  }

  svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    path {
      fill: $gray-text;
    }
  }

  svg.close-icon {
    width: 24px;
    height: 24px;
  }

  .detailed-contact-bx {
    margin: 4px 0;
    h2 {
      color: $gray-text;
    }
    .expiration {
      color: $medium-badge;
      font-weight: 600;
      font-size: 0.8rem;
      cursor: pointer;
      width: 180px;
      &:hover {
        border-bottom: 1px solid $medium-badge;
      }
    }
    .contact-header {
      margin-left: 15px;
      .contact-type {
        margin-right: 4px;
      }
      .contact-name {
        font-family: "Inter";
        font-size: 0.9rem;
        font-weight: 400;
        margin-right: 4px;
      }
      svg {
        margin-left: 4px;
      }
    }
    .detailed-contact {
      margin-left: 15px;
    }
  }

  .badge {
    font-size: 0.6rem;
    padding: 2px 8px;
    border-radius: 16px;
    text-align: center;
    display: none;
    font-weight: 600;
    &.medium {
      display: inline-block;
      color: $dark-blue;
      background-color: $medium-badge;
    }
    &.high {
      display: inline-block;
      color: $white-text;
      background-color: $high-badge;
    }
  }

  .patient-info {
    user-select: none;
  }

  .contact-menu {
    .expansible-menu {
      margin-left: 30px;
      padding-left: 16px;
    }
    .edition {
      cursor: pointer;
      margin: 10px 0 0 30px;
      padding-left: 18px;
      color: $mint-light;
      font-size: 0.6rem;
      font-weight: 600;
      p {
        width: fit-content;
      }
      &:hover p {
        border-bottom: 1px solid $mint-light;
      }
    }
  }

  .sticky-notes,
  .patient-history {
    margin: 30px 0;
  }

  .note {
    margin: 5px 0 10px;
  }
  .body {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 30px 30px 30px 35px;
    .patient-info {
      margin-bottom: 10px;
    }
  }
  .footer {
    padding: 16px 33px;
    background-color: $black;
    display: flex;
    align-items: center;
    max-height: 56px;
    align-self: flex-end;
  }
  h3 {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .normal-text {
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Inter";
    &:hover {
      border-bottom: 1px solid $gray-text;
    }
  }

  .expansible-menu {
    border-left: solid 2px $gray-text;
    padding-left: 27px;
    margin-top: 20px;
    z-index: 0;
    user-select: none;
    transition: all ease 0.25s;
  }

  .overlay-sidebar::after {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    content: "";
    background-color: rgba(0, 0, 0, $alpha: 0.5);
  }
  .patient-menu-container {
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .patient-menu {
    padding: 6% 7%;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid $black;

    &:hover {
      background-color: $ochsner-blue;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        p {
          font-size: 1rem;
          font-weight: 600;
          color: $gray-six;
        }
      }
      .arrow {
        height: 32px !important;
        width: 32px !important;
      }
    }

    &.expanded {
      background-color: $ochsner-blue;
    }

    &.active {
      background-color: $ochsner-blue;
    }
  }

  .patient-history {
    h3 {
      font-family: "Inter";
      span {
        font-weight: 400;
        font-size: 0.9rem;
      }
    }
    .expansible-menu {
      margin-left: 30px;
      padding-left: 16px;
      font-family: "Inter";
      .hour {
        font-weight: 600;
      }
      .reason {
        font-size: 0.75rem;
        color: $textarea-plh;
      }
    }
    .call-info {
      margin-bottom: 15px;
    }
  }

  .sticky-notes {
    h3 {
      margin: 0 8px 0 0px;
      width: fit-content;
    }
    .current-note-bx {
      margin: 6px 0 0;
      overflow: hidden;
      max-width: 300px;
      white-space: normal;
      .current-note {
        margin-right: 0;
      }
    }
  }

  .assign-contact-form {
    margin-top: 12px;
    margin-left: 13px;
    h2 {
      font-family: "Inter";
    }
    .contact {
      width: 100%;
      margin: 12px 0px 30px;
      padding: 19px 22px;
      background-color: $ochsner-blue;
      color: $gray-six;

      .title {
        h2 {
          font-size: 1.1rem;
          font-weight: 700;
        }
        .subtitle {
          font-weight: 400;
        }
        margin-bottom: 16px;
      }

      label {
        font-size: 0.8rem;
      }
    }
  }

  .sos-alert {
    margin-bottom: 28px;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-20%);
  }
  to {
    transform: translateX(0);
  }
}
